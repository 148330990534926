@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap);
html, body {
    height: 100% !important;
    background: #ffffff;
}

@-webkit-keyframes Login_spinLogo__9BDaB {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    } to {
          -webkit-transform: rotate(360deg);
                  transform: rotate(360deg);
      }
}

@keyframes Login_spinLogo__9BDaB {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    } to {
          -webkit-transform: rotate(360deg);
                  transform: rotate(360deg);
      }
}

.Login_spinLogo__9BDaB {
    -webkit-animation-name: Login_spinLogo__9BDaB;
    -webkit-animation-duration: 1000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    -moz-animation-name: Login_spinLogo__9BDaB;
    -moz-animation-duration: 1000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;

    animation-name: Login_spinLogo__9BDaB;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

body {
    position: relative;
    min-height: 100%;
}

.Login_page__2odH4 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

}

.Login_page__2odH4 img {
    width: 100px
}

.Login_form__aSkDO {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
}

.Login_form__aSkDO input {
    font-family: "Google Sans";
    padding: 7px 20px;
    border-radius: 25px;
    width: 170px;
    border: 2px solid #495057;
    color: #495057;
    text-align: center;
    outline: none;
    margin: 10px 0;
    background: linear-gradient(120deg, #fff 0%, #fff 45%, #495057 50%, #495057 100%);
    background-size: 400% 400%;
    background-position: 0;
    transition: all 100ms;
}

.Login_form__aSkDO input:focus {
    background-position: 99%;
    color: #fff;
}

.Login_form__aSkDO input:focus::-webkit-input-placeholder {
    color: #fff;
}

.Login_form__aSkDO input:focus:-ms-input-placeholder {
    color: #fff;
}

.Login_form__aSkDO input:focus::placeholder {
    color: #fff;
}

.Login_form__aSkDO button {
    font-family: "Google Sans";
    padding: 10px;
    width: 150px;
    margin: 10px auto;
    border-radius: 25px;
    border: 2px solid #495057;
    background: linear-gradient(120deg, #495057 0%, #495057 45%, rgba(122,186,230,1) 50%, rgba(41,128,185,1) 100%);
    background-size: 400% 400%;
    background-position: 0;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    cursor: pointer;
    transition: all 500ms;
}

/*.form button:hover {*/
/*    -webkit-box-shadow: 9px 9px 15px 3px rgba(73,80,87,0.35);*/
/*    box-shadow: 9px 9px 15px 3px rgba(73,80,87,0.35);*/
/*}*/
.Login_form__aSkDO button:hover {
    background-position: 99%;
    border-color: rgba(255,255,255,0);
}


.Login_passstatus__1-gpr {
    color: #fff;
    font-family: "Google Sans";
    width: 100%;
    height: 20px;
    font-size: 13px;
    text-align: center;
    transition: all 200ms;
    width: 200px;
    margin: 0px auto
}
.Login_incorrect__3fO44 {
    color: red;
}
.page {
    font-family: "Google Sans";
    font-weight: 400;
    color: #32353a;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    width: 95%;
    padding: 10px;
}

.container-title {
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.button-logout {
    color: #fff;
    border: none;
    font-size: 14px;
    font-weight: bold;
    background: #32353a;
    cursor: pointer;
    text-transform: uppercase;
    padding: 10px;
    border-radius: 10px;
    transition: 250ms all;
}
.button-logout:hover {
    color: #32353a;
    background: #fff;
    transition: 250ms all;
}

.apps-items-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.apps-item {
    width: 170px;
    height: 220px;
    margin: 25px;
    border-radius: 10px;
    background-size: 400% 400% !important;
    background-position: 0;
    overflow: hidden;
    -webkit-transform: none;
            transform: none;
    transition: background-position 400ms, -webkit-transform 250ms;
    transition: transform 250ms, background-position 400ms;
    transition: transform 250ms, background-position 400ms, -webkit-transform 250ms;
}

.apps-item:nth-child(1)  {
    background: linear-gradient(120deg, rgba(238,238,238,1) 0%, rgba(247,247,247,1) 45%, rgba(122,186,230,1) 50%, rgba(41,128,185,1) 100%);
}
.apps-item:nth-child(2) {
    background: linear-gradient(120deg, rgba(238,238,238,1) 0%, rgba(247,247,247,1) 45%, rgba(165,168,255,1) 50%, rgba(41,128,185,1) 100%);
}
.apps-item:nth-child(3) {
    background: linear-gradient(120deg, rgba(238,238,238,1) 0%, rgba(247,247,247,1) 45%, #DFA0FF 50%, #828ADC 100%);
}
.apps-item:nth-child(4) {
    background: linear-gradient(120deg, rgba(238,238,238,1) 0%, rgba(247,247,247,1) 45%, #FFA0BE 50%, #DC8282 100%);
}

.apps-item:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0 2px 9px 0 rgba(0,0,0, 0.4);
    cursor: pointer;
    background-position: 100%;
    transition: background-position 400ms, -webkit-transform 250ms;
    transition: transform 250ms, background-position 400ms;
    transition: transform 250ms, background-position 400ms, -webkit-transform 250ms;
}

.apps-item .row {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.apps-item .row img {
    height: 60px;
    -webkit-filter: invert(13%) sepia(7%) saturate(22%) hue-rotate(320deg) brightness(93%) contrast(85%);
            filter: invert(13%) sepia(7%) saturate(22%) hue-rotate(320deg) brightness(93%) contrast(85%);
    transition: -webkit-filter 400ms;
    transition: filter 400ms;
    transition: filter 400ms, -webkit-filter 400ms;
}

.apps-item:hover .row {
    -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(296deg) brightness(106%) contrast(105%);
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(296deg) brightness(106%) contrast(105%);
    transition: -webkit-filter 400ms;
    transition: filter 400ms;
    transition: filter 400ms, -webkit-filter 400ms;
}
.apps-item:hover h3 {
    color: #fff;
}
.apps-item:hover p {
    color: #fff;
}


.apps-item h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 2px;
    font-weight: bold;
    color: #2d2d2d;
    transition: color 400ms;
}

.apps-item p {
    text-align: center;
    padding: 40px 5px 5px 5px;
    font-size: 0.7em;
    color: #2d2d2d;
    transition: color 400ms;
}

.controls-section {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding: 20px 0 0;
}
.controls-section-header {
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 100%;
}


.stats-item {
    width: 250px;
    height: 60px;
    background: #eaeaea;
    display: flex;
    border-radius: 5px;
    justify-content: space-around;
    -webkit-transform: none;
            transform: none;
    transition: -webkit-transform 250ms;
    transition: transform 250ms;
    transition: transform 250ms, -webkit-transform 250ms;
    margin: 25px;
}

.stats-item:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: -webkit-transform 250ms;
    transition: transform 250ms;
    transition: transform 250ms, -webkit-transform 250ms;
}

.stats-item .img img {
    height: 55px;
    margin-top: 5px;
}

.stats-item h3 {
   line-height: 60px;
}
.stats-item button {
    background: #3498db;
    border: none;
    border-radius: 3px;
    width: 80px;
    height: 25px;
    margin-top: 18px;
    font-size: 12px;
    font-family: "Google Sans";
    color: #fff;
    cursor: pointer;
}

.stats-item button:hover {
    background: #2980b9;
}
* {
    margin: 0;
    padding: 0;
    outline: none;
}

html, body {
    font-family: 'Nunito', sans-serif;
}

.macro-form {
    width: 300px;
    height: 550px;
    border: 1px solid;
}

.block-radio label input[type=radio] {
    display: none;
}

.block-radio label {
    margin-right: 7px;
    margin-top: 7px;
    display: inline-block;
    border: 1px solid gray;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.block-radio label span {
    display: block;
    padding: 5px 7px;
}

.block-radio label:hover {
    cursor: pointer;
}

.block-radio  label input[type=radio]:checked + span {
    background: gray;
    color: #fff;
}

.block-radio label:hover {
    background: gainsboro;
}


.basic-input {
    font-family: 'Nunito', sans-serif;
}

.btn-set {
    display: flex;
}
.btn-set button {
    width: 50%;
    padding: 10px 0;
}
.btn-set .ready {
    background: #abee6b;
}

a>div { display: none; }
a:hover>div { display: block; }
/*a:hover>div img { position: absolute; top: 0; }*/
* {
    margin: 0;
    padding: 0;
    outline: none;
}

html, body {
    font-family: 'Nunito', sans-serif;
}

.macro-form {
    width: 300px;
    height: 550px;
    border: 1px solid;
}

.block-radio label input[type=radio] {
    display: none;
}

.block-radio label {
    margin-right: 7px;
    margin-top: 7px;
    display: inline-block;
    border: 1px solid gray;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.block-radio label span {
    display: block;
    padding: 5px 7px;
}

.block-radio label:hover {
    cursor: pointer;
}

.block-radio  label input[type=radio]:checked + span {
    background: gray;
    color: #fff;
}

.block-radio label:hover {
    background: gainsboro;
}


.basic-input {
    font-family: 'Nunito', sans-serif;
}

.btn-set {
    display: flex;
}
.btn-set button {
    width: 50%;
    padding: 10px 0;
}
.btn-set .ready {
    background: #abee6b;
}

a>div { display: none; }
a:hover>div { display: block; }
/*a:hover>div img { position: absolute; top: 0; }*/
* {
    margin: 0;
    padding: 0;
    outline: none;
}

html, body {
    font-family: 'Nunito', sans-serif;
}

.macro-form {
    width: 300px;
    height: 550px;
    border: 1px solid;
}

.block-radio label input[type=radio] {
    display: none;
}

.block-radio label {
    margin-right: 7px;
    margin-top: 7px;
    display: inline-block;
    border: 1px solid gray;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.block-radio label span {
    display: block;
    padding: 5px 7px;
}

.block-radio label:hover {
    cursor: pointer;
}

.block-radio  label input[type=radio]:checked + span {
    background: gray;
    color: #fff;
}

.block-radio label:hover {
    background: gainsboro;
}


.basic-input {
    font-family: 'Nunito', sans-serif;
}

.btn-set {
    display: flex;
}
.btn-set button {
    width: 50%;
    padding: 10px 0;
}
.btn-set .ready {
    background: #abee6b;
}

a>div { display: none; }
a:hover>div { display: block; }
/*a:hover>div img { position: absolute; top: 0; }*/
@font-face {
    font-family: 'Google Sans';
    src: url(/static/media/GoogleSans-MediumItalic.5aad00dd.eot);
    src: local('Google Sans Medium Italic'), local('GoogleSans-MediumItalic'),
        url(/static/media/GoogleSans-MediumItalic.5aad00dd.eot?#iefix) format('embedded-opentype'),
        url(/static/media/GoogleSans-MediumItalic.a3807d9b.woff2) format('woff2'),
        url(/static/media/GoogleSans-MediumItalic.ced93328.woff) format('woff'),
        url(/static/media/GoogleSans-MediumItalic.1fb37d8d.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Google Sans';
    src: url(/static/media/GoogleSans-Bold.9e5ca2a4.eot);
    src: local('Google Sans Bold'), local('GoogleSans-Bold'),
        url(/static/media/GoogleSans-Bold.9e5ca2a4.eot?#iefix) format('embedded-opentype'),
        url(/static/media/GoogleSans-Bold.296439eb.woff2) format('woff2'),
        url(/static/media/GoogleSans-Bold.3b37e86b.woff) format('woff'),
        url(/static/media/GoogleSans-Bold.8d814c21.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url(/static/media/GoogleSans-Italic.69182ce7.eot);
    src: local('Google Sans Italic'), local('GoogleSans-Italic'),
        url(/static/media/GoogleSans-Italic.69182ce7.eot?#iefix) format('embedded-opentype'),
        url(/static/media/GoogleSans-Italic.2be1a947.woff2) format('woff2'),
        url(/static/media/GoogleSans-Italic.957b05fc.woff) format('woff'),
        url(/static/media/GoogleSans-Italic.bb82826a.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Google Sans';
    src: url(/static/media/GoogleSans-Medium.6a348b13.eot);
    src: local('Google Sans Medium'), local('GoogleSans-Medium'),
        url(/static/media/GoogleSans-Medium.6a348b13.eot?#iefix) format('embedded-opentype'),
        url(/static/media/GoogleSans-Medium.584474a6.woff2) format('woff2'),
        url(/static/media/GoogleSans-Medium.20a76f91.woff) format('woff'),
        url(/static/media/GoogleSans-Medium.2ecb2226.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url(/static/media/GoogleSans-Regular.1e9a2f61.eot);
    src: local('Google Sans Regular'), local('GoogleSans-Regular'),
        url(/static/media/GoogleSans-Regular.1e9a2f61.eot?#iefix) format('embedded-opentype'),
        url(/static/media/GoogleSans-Regular.803df2da.woff2) format('woff2'),
        url(/static/media/GoogleSans-Regular.af22ed99.woff) format('woff'),
        url(/static/media/GoogleSans-Regular.6c1be5ca.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url(/static/media/GoogleSans-BoldItalic.e8f006f2.eot);
    src: local('Google Sans Bold Italic'), local('GoogleSans-BoldItalic'),
        url(/static/media/GoogleSans-BoldItalic.e8f006f2.eot?#iefix) format('embedded-opentype'),
        url(/static/media/GoogleSans-BoldItalic.675e398d.woff2) format('woff2'),
        url(/static/media/GoogleSans-BoldItalic.1deb1e78.woff) format('woff'),
        url(/static/media/GoogleSans-BoldItalic.9e84d540.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}


    html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
