html, body {
    height: 100% !important;
    background: #ffffff;
}

@keyframes spinLogo {
    from {
        transform: rotate(0deg);
    } to {
          transform: rotate(360deg);
      }
}

.spinLogo {
    -webkit-animation-name: spinLogo;
    -webkit-animation-duration: 1000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    -moz-animation-name: spinLogo;
    -moz-animation-duration: 1000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;

    animation-name: spinLogo;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

body {
    position: relative;
    min-height: 100%;
}

.page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

}

.page img {
    width: 100px
}

.form {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
}

.form input {
    font-family: "Google Sans";
    padding: 7px 20px;
    border-radius: 25px;
    width: 170px;
    border: 2px solid #495057;
    color: #495057;
    text-align: center;
    outline: none;
    margin: 10px 0;
    background: linear-gradient(120deg, #fff 0%, #fff 45%, #495057 50%, #495057 100%);
    background-size: 400% 400%;
    background-position: 0;
    transition: all 100ms;
}

.form input:focus {
    background-position: 99%;
    color: #fff;
}

.form input:focus::placeholder {
    color: #fff;
}

.form button {
    font-family: "Google Sans";
    padding: 10px;
    width: 150px;
    margin: 10px auto;
    border-radius: 25px;
    border: 2px solid #495057;
    background: linear-gradient(120deg, #495057 0%, #495057 45%, rgba(122,186,230,1) 50%, rgba(41,128,185,1) 100%);
    background-size: 400% 400%;
    background-position: 0;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    cursor: pointer;
    transition: all 500ms;
}

/*.form button:hover {*/
/*    -webkit-box-shadow: 9px 9px 15px 3px rgba(73,80,87,0.35);*/
/*    box-shadow: 9px 9px 15px 3px rgba(73,80,87,0.35);*/
/*}*/
.form button:hover {
    background-position: 99%;
    border-color: rgba(255,255,255,0);
}


.passstatus {
    color: #fff;
    font-family: "Google Sans";
    width: 100%;
    height: 20px;
    font-size: 13px;
    text-align: center;
    transition: all 200ms;
    width: 200px;
    margin: 0px auto
}
.incorrect {
    color: red;
}