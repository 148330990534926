.page {
    font-family: "Google Sans";
    font-weight: 400;
    color: #32353a;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    width: 95%;
    padding: 10px;
}

.container-title {
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.button-logout {
    color: #fff;
    border: none;
    font-size: 14px;
    font-weight: bold;
    background: #32353a;
    cursor: pointer;
    text-transform: uppercase;
    padding: 10px;
    border-radius: 10px;
    transition: 250ms all;
}
.button-logout:hover {
    color: #32353a;
    background: #fff;
    transition: 250ms all;
}

.apps-items-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.apps-item {
    width: 170px;
    height: 220px;
    margin: 25px;
    border-radius: 10px;
    background-size: 400% 400% !important;
    background-position: 0;
    overflow: hidden;
    transform: none;
    transition: transform 250ms, background-position 400ms;
}

.apps-item:nth-child(1)  {
    background: linear-gradient(120deg, rgba(238,238,238,1) 0%, rgba(247,247,247,1) 45%, rgba(122,186,230,1) 50%, rgba(41,128,185,1) 100%);
}
.apps-item:nth-child(2) {
    background: linear-gradient(120deg, rgba(238,238,238,1) 0%, rgba(247,247,247,1) 45%, rgba(165,168,255,1) 50%, rgba(41,128,185,1) 100%);
}
.apps-item:nth-child(3) {
    background: linear-gradient(120deg, rgba(238,238,238,1) 0%, rgba(247,247,247,1) 45%, #DFA0FF 50%, #828ADC 100%);
}
.apps-item:nth-child(4) {
    background: linear-gradient(120deg, rgba(238,238,238,1) 0%, rgba(247,247,247,1) 45%, #FFA0BE 50%, #DC8282 100%);
}

.apps-item:hover {
    transform: scale(1.1);
    -webkit-box-shadow: 0 2px 9px 0 rgba(0,0,0 0.4);
    -moz-box-shadow: 0 2px 9px 0 rgba(0,0,0 0.4);
    box-shadow: 0 2px 9px 0 rgba(0,0,0, 0.4);
    cursor: pointer;
    background-position: 100%;
    transition: transform 250ms, background-position 400ms;
}

.apps-item .row {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.apps-item .row img {
    height: 60px;
    filter: invert(13%) sepia(7%) saturate(22%) hue-rotate(320deg) brightness(93%) contrast(85%);
    transition: filter 400ms;
}

.apps-item:hover .row {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(296deg) brightness(106%) contrast(105%);
    transition: filter 400ms;
}
.apps-item:hover h3 {
    color: #fff;
}
.apps-item:hover p {
    color: #fff;
}


.apps-item h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 2px;
    font-weight: bold;
    color: #2d2d2d;
    transition: color 400ms;
}

.apps-item p {
    text-align: center;
    padding: 40px 5px 5px 5px;
    font-size: 0.7em;
    color: #2d2d2d;
    transition: color 400ms;
}

.controls-section {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding: 20px 0 0;
}
.controls-section-header {
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 100%;
}


.stats-item {
    width: 250px;
    height: 60px;
    background: #eaeaea;
    display: flex;
    border-radius: 5px;
    justify-content: space-around;
    transform: none;
    transition: transform 250ms;
    margin: 25px;
}

.stats-item:hover {
    transform: scale(1.1);
    transition: transform 250ms;
}

.stats-item .img img {
    height: 55px;
    margin-top: 5px;
}

.stats-item h3 {
   line-height: 60px;
}
.stats-item button {
    background: #3498db;
    border: none;
    border-radius: 3px;
    width: 80px;
    height: 25px;
    margin-top: 18px;
    font-size: 12px;
    font-family: "Google Sans";
    color: #fff;
    cursor: pointer;
}

.stats-item button:hover {
    background: #2980b9;
}