@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
* {
    margin: 0;
    padding: 0;
    outline: none;
}

html, body {
    font-family: 'Nunito', sans-serif;
}

.macro-form {
    width: 300px;
    height: 550px;
    border: 1px solid;
}

.block-radio label input[type=radio] {
    display: none;
}

.block-radio label {
    margin-right: 7px;
    margin-top: 7px;
    display: inline-block;
    border: 1px solid gray;
    user-select: none;
}
.block-radio label span {
    display: block;
    padding: 5px 7px;
}

.block-radio label:hover {
    cursor: pointer;
}

.block-radio  label input[type=radio]:checked + span {
    background: gray;
    color: #fff;
}

.block-radio label:hover {
    background: gainsboro;
}


.basic-input {
    font-family: 'Nunito', sans-serif;
}

.btn-set {
    display: flex;
}
.btn-set button {
    width: 50%;
    padding: 10px 0;
}
.btn-set .ready {
    background: #abee6b;
}

a>div { display: none; }
a:hover>div { display: block; }
/*a:hover>div img { position: absolute; top: 0; }*/